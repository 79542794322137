<template
  ><div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Permissions')"
    />
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="permissionTable"
            v-loading="loading"
            :data="permissions"
            @row-click="handleSingleClick"
            @current-change="handleCurrentChange"
            highlight-current-row
            class="list-table"
          >
            <el-table-column :label="__('Permission Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "PermissionsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      search: "",
      filters: ["id"],
      sortByColumns: ["name", "id"],
      currentRow: ""
    };
  },
  computed: {
    ...mapState("permissions", {
      permissions: state => state.permissions,
      loading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions("permissions", {
      contentAPI: "getPermissions",
      deleteContentMethod: "deletePermission"
    }),
    handleClear() {
      this.$refs.permissionTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.permissionTable &&
            this.$refs.permissionTable.setCurrentRow(this.permissions[val]);
        } else if (val === -1) {
          this.$refs.permissionTable &&
            this.$refs.permissionTable.setCurrentRow();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
