<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "PermissionsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("permissions", {
      contents: state => state.permissions
    })
  },

  methods: {
    initContent() {
      return {
        name: ""
      };
    }
  },

  mounted() {
    this.contentType = "permission";
    this.primaryKey = "id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
