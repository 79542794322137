<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Permission Name") }}</div>
    <div class="details">{{ contentFormInfo.name }}</div>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false">
        <div style="max-height: 100vh;">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <page-header
                  style="padding-left: 0"
                  :title="__('Permission')"
                  :contentId="id"
                />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Permission Name')" prop="name">
                  <el-input v-model="contentForm.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: __("Permission name is required")
          }
        ]
      }
    };
  },

  computed: {
    ...mapState("permissions", {
      isLoading: state => state.isLoading
    })
  },

  methods: {
    ...mapActions("permissions", {
      createPermission: "createPermission",
      updatePermission: "updatePermission",
      deleteContentMethod: "deletePermission"
    }),
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createPermission : this.updatePermission;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Permission created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Permission updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";
</style>
